// modals
.modal{
    overscroll-behavior: contain;
    &.modal--cv-head-edit .modal-dialog{
        max-width: 800px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--800 .modal-dialog{
        max-width: 800px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--900 .modal-dialog{
        max-width: 900px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--640 .modal-dialog{
        max-width: 640px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--700 .modal-dialog{
        max-width: 700px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--480 .modal-dialog{
        max-width: 480px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--mt-115 .modal-dialog{
        margin-top: 115px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--crop-avatar .modal-dialog{
        max-width: 680px;
        margin-top: 115px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    &.modal--cv-block-cv-item-edit .modal-dialog{
        max-width: 840px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    // cv portfolio (overview)
    &.modal--cv-block-portfolio-item-edit .modal-dialog{
        max-width: 1024px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    // cv portfolio (detail)
    &.modal--cv-block-portfolio-item-edit--detail .modal-dialog{
        max-width: 900px;
        margin-top: 145px;
        .modal-content{
            min-height: 0 !important;
        }
    }
    .modal-dialog{
        height: auto !important;
        overflow: auto !important;
        border-radius: 12px !important;
        margin-top: 90px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
        overflow: visible !important;
        .modal-content{
            overflow: visible !important;
            border-radius: 8px !important;
            border: none;
            overflow-x: hidden;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .modal-wrap{
            border-radius: 8px;
            background-color: #fff;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.modal-backdrop{
    background-color: #000 !important;
    &.modal-backdrop--route{
        z-index: 1040 !important;
    }
    &.modal-backdrop--confirmaction{
        z-index: 1041 !important;
    }
}
.modal--route{
    z-index: 1041 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.app-modal-head{
    display: flex;
    justify-content: space-between;
    padding: 25px 20px;
    border-bottom: 1px solid #E5E9EB;
    &.app-modal-head--sm{
        padding: 20px 20px;
    }
    h2{
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
    }
    button.app-modal-close{
        height: 30px;
        width: 25px;
        display: flex;
        align-items: center;
    }
}
.app-modal-body{
    padding: 30px 20px;
    &.app-modal-body--sm{
        padding: 20px 20px;
    }
}
.app-modal-button-row{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-top: 1px solid #E5E9EB;
}