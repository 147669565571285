// field
.app-field-richtext{

}

// editor
.ck.ck-editor__editable_inline>:first-child{
    margin-top: 0 !important;
}
.ck.ck-editor__editable_inline>:last-child{
    margin-bottom: 0 !important;
}
.ck-content{
    border: 1px solid var(--ck-color-base-border) !important;
    padding: 10px 10px !important;
    min-height: 148px;
}
.ck-content, .ck-output{
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    color: var(--text-color);
    display: flow-root;
    h2{
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 7px;
        margin-top: 24px;
        color: var(--primary-blue);
    }
    h3{
        font-size: 15px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 4px;
        margin-top: 15px;
        color: var(--primary-blue);
    }
    p, ul, ol{
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    ul{
        list-style-type: disc;
        ul{
            list-style-type: circle;
            ul{
                list-style-type: square;
            }
        }
    }
    ol{
        list-style-type: decimal;
    }
    a{
        color: var(--primary-blue);
        text-decoration: underline;
    }
    strong{
        font-weight: 500;
    }
    i{
        font-style: italic;
    }
    u{
        text-decoration: underline;
    }
}
.ck-output{
    &>:first-child{
        margin-top: 0 !important;
    }
    &>:last-child{
        margin-bottom: 0 !important;
    }
}
.ck-powered-by-balloon{
    display: none !important;
}
.ck-toolbar__separator{
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    background-color: #E5E9EB !important;
}
.ck-sticky-panel__content_sticky{
    box-shadow: 0px 5px 8px -5px rgba(53, 84, 119, 0.20) !important;
}
.ck-editor{
    &:not(:focus-within){
        .ck-button{
            color: var(--ck-color-text) !important;
            background-color: transparent !important;
        }
    }
}
.ck-button{
    cursor: pointer !important;
}

:root body {
    // --ck-color-base-foreground: #fafafa;
    // --ck-color-base-background: #fff;
    // --ck-color-base-border: #E5E9EB;
    // --ck-color-base-action: #53a336;
    // --ck-color-base-focus: #6cb5f9;
    // --ck-color-base-text: #333;
    // --ck-color-base-active: #fff;
    // --ck-color-base-active-focus: #fff;
    // --ck-color-base-error: #db3700;
    // --ck-color-focus-border-coordinates: 218,81.8%,56.9%;
    // --ck-color-focus-border: hsl(var(--ck-color-focus-border-coordinates));
    // --ck-color-focus-outer-shadow: transparent;
    // --ck-color-focus-disabled-shadow: rgba(119,186,248,.3);
    // --ck-color-focus-error-shadow: rgba(255,64,31,.3);
    // --ck-color-text: var(--primary-blue); // changed
    // --ck-color-shadow-drop: rgba(0,0,0,.15);
    // --ck-color-shadow-drop-active: rgba(0,0,0,.2);
    // --ck-color-shadow-inner: rgba(0,0,0,.1);
    // --ck-color-button-default-background: transparent;
    --ck-color-button-default-hover-background: #{var(--medbook-blue-5-pct)};
    --ck-color-button-default-active-background: #{var(--medbook-blue-5-pct)};
    // --ck-color-button-default-disabled-background: transparent;
    // --ck-color-button-on-background: var(--medbook-blue);
    --ck-color-button-on-hover-background: #{var(--medbook-blue-5-pct)};
    --ck-color-button-on-active-background: #{var(--medbook-blue-5-pct)};
    // --ck-color-button-on-disabled-background: #f0f2f4;
    --ck-color-button-on-color: var(--primary-blue);
    // --ck-color-button-action-background: var(--ck-color-base-action);
    // --ck-color-button-action-hover-background: #4d9d30;
    // --ck-color-button-action-active-background: #4d9d30;
    // --ck-color-button-action-disabled-background: #7ec365;
    // --ck-color-button-action-text: var(--ck-color-base-background);
    // --ck-color-button-save: #008a00;
    // --ck-color-button-cancel: #db3700;
    // --ck-color-switch-button-off-background: #939393;
    --ck-color-switch-button-off-hover-background:  #{var(--medbook-blue-5-pct)};;
    // --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
    --ck-color-switch-button-on-hover-background: #{var(--medbook-blue-5-pct)};
    // --ck-color-switch-button-inner-background: var(--ck-color-base-background);
    // --ck-color-switch-button-inner-shadow: rgba(0,0,0,.1);
    // --ck-color-dropdown-panel-background: var(--ck-color-base-background);
    // --ck-color-dropdown-panel-border: var(--ck-color-base-border);
    // --ck-color-input-background: var(--ck-color-base-background);
    // --ck-color-input-border: var(--ck-color-base-border);
    // --ck-color-input-error-border: var(--ck-color-base-error);
    // --ck-color-input-text: var(--ck-color-base-text);
    // --ck-color-input-disabled-background: #f2f2f2;
    // --ck-color-input-disabled-border: var(--ck-color-base-border);
    // --ck-color-input-disabled-text: #757575;
    // --ck-color-list-background: var(--ck-color-base-background);
    --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
    --ck-color-list-button-on-background: #{var(--medbook-blue-5-pct)};
    --ck-color-list-button-on-background-focus: #{var(--medbook-blue-5-pct)};
    // --ck-color-list-button-on-text: var(--primary-blue);
    // --ck-color-panel-background: var(--ck-color-base-background);
    // --ck-color-panel-border: var(--ck-color-base-border);
    // --ck-color-toolbar-background: var(--ck-color-base-background);
    // --ck-color-toolbar-border: var(--ck-color-base-border);
    // --ck-color-tooltip-background: var(--ck-color-base-text);
    // --ck-color-tooltip-text: var(--ck-color-base-background);
    // --ck-color-engine-placeholder-text: #707070;
    // --ck-color-upload-bar-background: #6cb5f9;
    // --ck-color-link-default: #0000f0;
    // --ck-color-link-selected-background: rgba(31,176,255,.1);
    // --ck-color-link-fake-selection: rgba(31,176,255,.3);
    // --ck-color-highlight-background: #ff0;
    // --ck-disabled-opacity: .5;
    // --ck-focus-outer-shadow-geometry: 0 0 0 3px;
    --ck-focus-outer-shadow: none;
    // --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-disabled-shadow);
    // --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-error-shadow);
    // --ck-focus-ring: 1px solid var(--ck-color-focus-border);
    // --ck-font-size-base: 13px;
    // --ck-line-height-base: 1.84615;
    // --ck-font-size-tiny: 0.7em;
    // --ck-font-size-small: 0.75em;
    // --ck-font-size-normal: 1em;
    // --ck-font-size-big: 1.4em;
    // --ck-font-size-large: 1.8em;
    // --ck-ui-component-min-height: 2.3em;
    // 
    --ck-color-base-border: #E5E9EB;
    --ck-font-face: 'proxima-nova', sans-serif;
    --ck-border-radius: 4px;
    --ck-inner-shadow: none;
    --ck-drop-shadow: 0 1px 2px 1px var(--ck-color-shadow-drop);
    --ck-drop-shadow-active: 0 3px 6px 1px var(--ck-color-shadow-drop-active);
    --ck-spacing-unit: 0.6em;
    --ck-spacing-large: calc(var(--ck-spacing-unit)*1.5);
    --ck-spacing-standard: var(--ck-spacing-unit);
    --ck-spacing-medium: calc(var(--ck-spacing-unit)*0.8);
    --ck-spacing-small: 5px;
    --ck-spacing-tiny: calc(var(--ck-spacing-unit)*0.3);
    --ck-spacing-extra-tiny: calc(var(--ck-spacing-unit)*0.16);
    // 
    --ck-focus-ring: 1px solid transparent;
}