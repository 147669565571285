.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    // portfolio
    &.icon-portfolio{
        width: 50px;
        height: 50px;
        &.icon-portfolio--work-experience{ background-image: url(/assets/img/portfolio/work-experience.svg); }
        &.icon-portfolio--diploma{ background-image: url(/assets/img/portfolio/diploma.svg); }
        &.icon-portfolio--course{ background-image: url(/assets/img/portfolio/course.svg); }
        &.icon-portfolio--educational-activity{ background-image: url(/assets/img/portfolio/educational-activity.svg); }
        &.icon-portfolio--congress{ background-image: url(/assets/img/portfolio/congress.svg); }
        &.icon-portfolio--presentation{ background-image: url(/assets/img/portfolio/presentation.svg); }
        &.icon-portfolio--certificate{ background-image: url(/assets/img/portfolio/certificate.svg); }
        &.icon-portfolio--volunteer-work{ background-image: url(/assets/img/portfolio/volunteer-work.svg); }
        &.icon-portfolio--membership{ background-image: url(/assets/img/portfolio/membership.svg); }
        &.icon-portfolio--publication{ background-image: url(/assets/img/portfolio/publication.svg); }
        &.icon-portfolio--thesis{ background-image: url(/assets/img/portfolio/thesis.svg); }
        &.icon-portfolio--class{ background-image: url(/assets/img/portfolio/class.svg); }
        &.icon-portfolio--miscellaneous{ background-image: url(/assets/img/portfolio/miscellaneous.svg); }
        &.icon-portfolio--exam{ background-image: url(/assets/img/portfolio/exam.svg); }
        &.icon-portfolio--literature{ background-image: url(/assets/img/portfolio/literature.svg); }
    }
    // cv
    &.icon-cv{
        width: 30px;
        height: 30px;
        display: flex;
        &.icon-cv--size-20{
            width: 20px;
            height: 20px;
        }
        // from portfolio
        &.icon-cv--work-experience{ background-image: url(/assets/img/cv/work-experience.svg); }
        &.icon-cv--diploma{ background-image: url(/assets/img/cv/diploma.svg); }
        &.icon-cv--course{ background-image: url(/assets/img/cv/course.svg); }
        &.icon-cv--educational-activity{ background-image: url(/assets/img/cv/educational-activity.svg); }
        &.icon-cv--congress{ background-image: url(/assets/img/cv/congress.svg); }
        &.icon-cv--presentation{ background-image: url(/assets/img/cv/presentation.svg); }
        &.icon-cv--certificate{ background-image: url(/assets/img/cv/certificate.svg); }
        &.icon-cv--volunteer-work{ background-image: url(/assets/img/cv/volunteer-work.svg); }
        &.icon-cv--membership{ background-image: url(/assets/img/cv/membership.svg); }
        &.icon-cv--publication{ background-image: url(/assets/img/cv/publication.svg); }
        &.icon-cv--thesis{ background-image: url(/assets/img/cv/thesis.svg); }
        &.icon-cv--class{ background-image: url(/assets/img/cv/class.svg); }
        &.icon-cv--miscellaneous{ background-image: url(/assets/img/cv/miscellaneous.svg); }
        &.icon-cv--exam{ background-image: url(/assets/img/cv/exam.svg); }
        &.icon-cv--literature{ background-image: url(/assets/img/cv/literature.svg); }
        // extra
        &.icon-cv--introduction{ background-image: url(/assets/img/cv/introduction.svg); }
        &.icon-cv--interests{ background-image: url(/assets/img/cv/interests.svg); }
        &.icon-cv--language-skills{ background-image: url(/assets/img/cv/language-skills.svg); }
        &.icon-cv--experience{ background-image: url(/assets/img/cv/experience.svg); }
        &.icon-cv--talents{ background-image: url(/assets/img/cv/talents.svg); }
        &.icon-cv--skills{ background-image: url(/assets/img/cv/skills.svg); }
        &.icon-cv--company{ background-image: url(/assets/img/cv/company.svg); }
        &.icon-cv--mobility{ background-image: url(/assets/img/cv/mobility.svg); }
        &.icon-cv--socials{ background-image: url(/assets/img/cv/socials.svg); }
    }
    // nav
    &.icon-nav{
        width: 20px;
        height: 20px;
        &.icon-nav--training{ background-image: url(/assets/img/nav/training.svg); }
        &.icon-nav--logbook{ background-image: url(/assets/img/nav/logbook.svg); }
        &.icon-nav--assessments{ background-image: url(/assets/img/nav/assessments.svg); }
        &.icon-nav--bulletinboard{ background-image: url(/assets/img/nav/bulletinboard.svg); }
        &.icon-nav--cases{ background-image: url(/assets/img/nav/cases.svg); }
        &.icon-nav--documents{ background-image: url(/assets/img/nav/documents.svg); }
        &.icon-nav--exercises{ background-image: url(/assets/img/nav/exercises.svg); }
        &.icon-nav--home{ background-image: url(/assets/img/nav/home.svg); }
        &.icon-nav--interventions{ background-image: url(/assets/img/nav/interventions.svg); }
        &.icon-nav--leeragenda{ background-image: url(/assets/img/nav/agenda.svg); }
        &.icon-nav--learning-agenda{ background-image: url(/assets/img/nav/target.svg); }
        &.icon-nav--portfolio{ background-image: url(/assets/img/nav/portfolio.svg); }
        &.icon-nav--reflections{ background-image: url(/assets/img/nav/reflection.svg); }
        &.icon-nav--seminargroup{ background-image: url(/assets/img/nav/seminargroup.svg); }
        &.icon-nav--assistants{ background-image: url(/assets/img/nav/students.svg); }
        &.icon-nav--surveys{ background-image: url(/assets/img/nav/surveys.svg); }
        &.icon-nav--goals{ background-image: url(/assets/img/nav/target.svg); }
        &.icon-nav--todos{ background-image: url(/assets/img/nav/todos.svg); }
    }
    // actions
    &.icon-action{
        width: 20px;
        height: 20px;
        &.icon-action--add-cv{ background-image: url(/assets/img/actions/add-cv.svg); }
        &.icon-action--remove-cv{ background-image: url(/assets/img/actions/remove-cv.svg); }
        &.icon-action--edit{ background-image: url(/assets/img/actions/edit.svg); }
        &.icon-action--delete{ background-image: url(/assets/img/actions/delete.svg); }
        &.icon-action--clipboard{ background-image: url(/assets/img/actions/clipboard.svg); }
        &.icon-action--star{ background-image: url(/assets/img/actions/star.svg); }
        &.icon-action--lock{ background-image: url(/assets/img/actions/lock.svg); }
        &.icon-action--merge{ background-image: url(/assets/img/actions/merge.svg); }
        &.icon-action--review{ background-image: url(/assets/img/actions/review.svg); }
        &.icon-action--upload{ background-image: url(/assets/img/actions/upload.svg); }
        &.icon-action--archive{ background-image: url(/assets/img/actions/archive.svg); }
        &.icon-action--unarchive{ background-image: url(/assets/img/actions/unarchive.svg); }
    }
    // pagination
    &.icon-pagination-prev{
        background-image: url(/assets/img/components/pagination/prev.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-pagination-next{
        background-image: url(/assets/img/components/pagination/next.svg);
        width: 16px;
        height: 16px;
    }
    // auth
    &.icon-auth-lock{
        background-image: url(/assets/img/auth/auth-lock.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-auth-add-user{
        background-image: url(/assets/img/auth/auth-add-user.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-auth-lock-check{
        background-image: url(/assets/img/auth/auth-lock-check.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-auth-credentials-check{
        background-image: url(/assets/img/auth/auth-credentials-check.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-auth-mfa{
        background-image: url(/assets/img/auth/auth-mfa.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-auth-check-green{
        background-image: url(/assets/img/auth/auth-check-green.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-validate-password{
        background-image: url(/assets/img/components/validate-password/validate-password.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-validate-password-checked{
        background-image: url(/assets/img/components/validate-password/validate-password-checked.svg);
        width: 20px;
        height: 20px;
    }
    // general
    &.icon-notifications{
        background-image: url(/assets/img/icons/notifications.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-actions{
        background-image: url(/assets/img/icons/actions.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-date-blue{
        background-image: url(/assets/img/icons/date-blue.svg);
        width: 15px;
        height: 15px;
    }
    &.icon-time-blue{
        background-image: url(/assets/img/icons/time-blue.svg);
        width: 15px;
        height: 15px;
    }
    &.icon-certificate-orange{
        background-image: url(/assets/img/icons/certificate-orange.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-attachment-orange{
        background-image: url(/assets/img/icons/attachment-orange.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-lock-gray-20{
        background-image: url(/assets/img/icons/lock-gray.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-lock-gray-25{
        background-image: url(/assets/img/icons/lock-gray.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-lock-gray-filled{
        background-image: url(/assets/img/icons/lock-gray-filled.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-file-xls{
        background-image: url(/assets/img/icons/file-xls.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-overview-emptystate{
        background-image: url(/assets/img/icons/overview-emptystate.svg);
        width: 40px;
        height: 40px;
    }
    &.icon-overview-emptystate-admin{
        background-image: url(/assets/img/icons/overview-emptystate-admin.svg);
        width: 40px;
        height: 40px;
    }
    &.icon-logout{
        background-image: url(/assets/img/icons/logout.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-pencil-gray{
        background-image: url(/assets/img/icons/pencil-gray.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-copy-clipboard-blue{
        background-image: url(/assets/img/icons/copy-clipboard-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-download-white{
        background-image: url(/assets/img/icons/download-white.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-email-blue{
        background-image: url(/assets/img/icons/email-blue.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-phone-blue{
        background-image: url(/assets/img/icons/phone-blue.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-address-blue{
        background-image: url(/assets/img/icons/address-blue.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-info-blue{
        background-image: url(/assets/img/icons/info-blue.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-sortable-blue{
        background-image: url(/assets/img/icons/sortable-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-add-blue{
        background-image: url(/assets/img/icons/add-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-x-round-gray{
        background-image: url(/assets/img/icons/x-round-gray.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-x-round-darkgray{
        background-image: url(/assets/img/icons/x-round-darkgray.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-close-modal-blue{
        background-image: url(/assets/img/icons/close-modal-blue.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-info-gray{
        background-image: url(/assets/img/icons/info-gray.svg);
        width: 15px;
        height: 15px;
    }
    &.icon-info-gray-filled{
        background-image: url(/assets/img/icons/info-gray-filled.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-pencil-blue{
        background-image: url(/assets/img/icons/pencil-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-show-blue{
        background-image: url(/assets/img/icons/show-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-hide-blue{
        background-image: url(/assets/img/icons/hide-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-multilang-blue{
        background-image: url(/assets/img/icons/multilang-blue.svg);
        width: 16px;
        height: 16px;
    }
    &.icon-location-pin-blue{
        background-image: url(/assets/img/icons/location-pin-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-trash-gray{
        background-image: url(/assets/img/icons/trash-gray.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-trash-blue{
        background-image: url(/assets/img/icons/trash-blue.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-info-orange{
        background-image: url(/assets/img/icons/info-orange.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-arrow-right-black{
        background-image: url(/assets/img/icons/arrow-right-black.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-chev-left-admin{
        background-image: url(/assets/img/icons/chev-left--admin.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-chev-right-admin{
        background-image: url(/assets/img/icons/chev-right--admin.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-confetti-green{
        background-image: url(/assets/img/icons/confetti-green.svg);
        width: 50px;
        height: 50px;
    }
    &.icon-upload-white{
        background-image: url(/assets/img/icons/upload-white.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-check-round-green{
        background-image: url(/assets/img/icons/check-round-green.svg);
        width: 20px;
        height: 20px;
    }
    // &.icon-check-round-green--15{
    //     background-image: url(/assets/img/icons/check-round-green.svg);
    //     width: 15px;
    //     height: 15px;
    // }
    &.icon-check-round-lightgreen{
        background-image: url(/assets/img/icons/check-round-lightgreen.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-check-round-black{
        background-image: url(/assets/img/icons/check-round-black.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-file-drop{
        background-image: url(/assets/img/icons/file-drop.svg);
        width: 40px;
        height: 40px;
    }
    &.icon-warning-black{
        background-image: url(/assets/img/icons/warning-black.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-warning-gray{
        background-image: url(/assets/img/icons/warning-gray.svg);
        width: 20px;
        height: 20px;
    }
    // &.icon-warning-black--15{
    //     background-image: url(/assets/img/icons/warning-black.svg);
    //     width: 15px;
    //     height: 15px;
    // }
    &.icon-send-white{
        background-image: url(/assets/img/icons/send-white.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-warning-tile-red{
        background-image: url(/assets/img/icons/warning-tile-red.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-warning-round-orange{
        background-image: url(/assets/img/icons/warning-round-orange.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-warning-round-red{
        background-image: url(/assets/img/icons/warning-round-red.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-map-black{
        background-image: url(/assets/img/icons/map-black.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-chev-round-blue-right{
        background-image: url(/assets/img/icons/chev-round-blue-right.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-verified-badge{
        background-image: url(/assets/img/icons/verified-badge.svg);
        width: 20px;
        height: 20px;
    }
    &.icon-facebook{
        background-image: url(/assets/img/icons/facebook.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-instagram{
        background-image: url(/assets/img/icons/insta.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-linkedin{
        background-image: url(/assets/img/icons/linkedin.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-x{
        background-image: url(/assets/img/icons/x.svg);
        width: 25px;
        height: 25px;
    }
    &.icon-tiktok{
        background-image: url(/assets/img/icons/tiktok.svg);
        width: 25px;
        height: 25px;
    }
}

// admin overrides
.body--admin{
    .icon-pagination-prev{
        background-image: url(/assets/img/components/pagination/prev--admin.svg) !important;
    }
    .icon-pagination-next{
        background-image: url(/assets/img/components/pagination/next--admin.svg) !important;
    }
    .icon-chev-multiselect{
        background-image: url(/assets/img/components/search/chev-multiselect--admin.svg) !important;
    }
    .app-search-input input{
        background: #ffffff url(/assets/img/components/search/search--admin.svg) right 8px center no-repeat !important;
        background-size: 20px !important;
    }
    .field-single-checkbox.field-single-checkbox--checked i{
        background-image: url(/assets/img/icons/checkbox-checked--admin.svg) !important;
    }
    .icon-x-round-darkgray{
        background-image: url(/assets/img/icons/x-round-darkgray--admin.svg);
    }
    .icon-close-modal-blue{
        background-image: url(/assets/img/icons/close-modal-blue--admin.svg);
    }
    .icon-trash-blue{
        background-image: url(/assets/img/icons/trash-blue--admin.svg);
    }
}