table{
    word-break: initial;
}
.table-pagination-wrap{
    position: relative;
    .pagination-wrap{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}
.table-wrap{
    overflow-x: auto;
}
table.app-table{
    width: 100%;
    word-break: break-word;
    // table default
    &.app-table--default{
        tr{
            &.tr--disabled{
                td{
                    *{
                        color: #91A2AF;
                    }
                    .pad{
                        color: #91A2AF;
                        *{
                            color: #91A2AF;
                        }
                        .date-stamp{
                            background-image: url(/assets/img/icons/date-blue--disabled.svg);
                            background-color: #F7F7F7;
                        }
                        .table-stamp-more-items{
                            background-color: #F7F7F7;
                        }
                    }
                }
            }
            &:last-of-type{
                td{
                    border-bottom: none;
                }
            }
            th{
                padding: 10px 20px 10px 0;
                color: #91A2AF;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
                text-transform: uppercase;
                border-bottom: 1px solid #E5E9EB;
                span{
                    color: #91A2AF;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    text-transform: uppercase;
                }
                &.sortable{
                    cursor: pointer;
                    user-select: none;
                    min-width: 70px;
                    &>div{
                        width: 0;
                        min-width: 100%;
                        &>span{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
                &>.ellipsis{
                    width: 0;
                    min-width: 100%;
                }
                i.order-icon{
                    position: relative;
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                    flex-shrink: 0;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url(/assets/img/icons/order.svg);
                    &.order-icon--asc{
                        background-image: url(/assets/img/icons/order-asc.svg);
                    }
                    &.order-icon--desc{
                        background-image: url(/assets/img/icons/order-desc.svg);
                    }
                    &.order-icon--admin{
                        &.order-icon--asc{
                            background-image: url(/assets/img/icons/order-asc--admin.svg);
                        }
                        &.order-icon--desc{
                            background-image: url(/assets/img/icons/order-desc--admin.svg);
                        }
                    }
                }
            }
            td{
                background: #fff;
                font-size: 15px;
                line-height: 20px;
                padding: 0;
                overflow: hidden;
                height: 1px;
                .display, .display *{
                    line-height: 24px;
                }
                .pad{
                    padding: 10px 20px 10px 0;
                    color: var(--text-color);
                    text-decoration: none !important;
                    display: block;
                    height: 100%;
                    width: 100%;
                    .table-link{
                        color: var(--primary-blue);
                        text-decoration: none;
                        padding-top: 10px;
                        margin-top: -10px;
                        padding-bottom: 10px;
                        margin-bottom: -10px;
                        display: inline;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
                border-bottom: 1px solid #E5E9EB;
                &.td--actions{
                    .pad{
                        padding-right: 0;
                    }
                }
                &.td--pad-5{
                    .pad{
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
                &.td--pad-6{
                    .pad{
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                }
                &.td--pad-7{
                    .pad{
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                }
                &.td--pad-8{
                    .pad{
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
                &.td--pad-9{
                    .pad{
                        padding-top: 9px;
                        padding-bottom: 9px;
                    }
                }
                &.td--pad-10{
                    .pad{
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
                &.td--pad-11{
                    .pad{
                        padding-top: 11px;
                        padding-bottom: 11px;
                    }
                }
                &.td--pad-12{
                    .pad{
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                }
                &.td--pad-15{
                    .pad{
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    // general
    &.app-table--clickable{
        tr{
            &:hover{
                td{
                    background-color: var(--medbook-blue-5-pct);
                    &.td--title-link{
                        .pad{
                            color: var(--primary-blue);
                            span:not(.passive){
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            &.tr--disabled{
                &:hover{
                    td{
                        &.td--title-link{
                            .pad{
                                color: #91A2AF;
                            }
                        }
                    }
                }
            }
            td{
                cursor: pointer;
            }
        }
    }
    tr{
        td{
            &.td--ellipsis{
                .pad{
                    white-space: nowrap !important;
                    text-overflow: ellipsis !important;
                    overflow: hidden !important;
                    width: 0 !important;
                    min-width: 100% !important;
                }
            }
            &.td--title-link{
                .pad{
                    color: var(--primary-blue);
                }
            }
        }
    }
    .location-stamp, .date-stamp{
        // width: 0;
        // min-width: 100%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
    }
}

td{
    vertical-align: top;
}
td *{
    vertical-align: bottom;
}

// moz cell height
@-moz-document url-prefix() {
    table.app-table{
        tr{
            td{
                height: 100% !important;
            }
        }
    }
}

// actions button
.actions-button-wrap{
    display: flex;
    align-items: flex-start;
    height: 20px;
    &.actions-button-wrap--detail{
        height: unset;
        padding: 7px 0;
        i.icon-actions{
            width: 20px;
            height: 20px;
        }
    }
    i.icon-actions{
        transition: .2s ease;
    }
    &[aria-describedby^="ngb-popover"]{
        i.icon-actions{
            filter: brightness(.2);
        }
    }
    .actions-button{
        display: inline-flex;
        transition: .1s ease;
        &:hover{
            filter: brightness(.2);
        }
    }
}