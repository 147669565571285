body::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content: 
        url(/assets/img/buttons/link-hide.svg)
        url(/assets/img/buttons/link-show.svg)
}

.app-button{
    white-space: nowrap;
    transition: .15s ease;
    box-shadow: none;
    outline: none;
    padding: 0 10px;
    height: 34px;
    border: none;
    display: inline-flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    border-radius: 4px;
    user-select: none;
    text-decoration: none;
    letter-spacing: .5px;
    text-transform: uppercase;
    cursor: pointer;
    &:disabled, &.disabled, &[inert]{
        opacity: .5;
        pointer-events: none;
        &:disabled{
            pointer-events: all;
            cursor: default;
        }
    }
    &.app-button--green{
        color: #fff;
        background-color: var(--medbook-green);
        &:hover:not(:disabled){
            background-color: #47CA75;
        }
        .amount{
            color: #3A7865;
            background-color: #EFF5F1;
        }
    }
    &.app-button--red{
        color: #fff;
        background-color: var(--medbook-red);
        &:hover:not(:disabled){
            background-color: #FE6666;
        }
    }
    &.app-button--orange{
        color: #fff;
        background-color: var(--medbook-orange);
        &:hover:not(:disabled){
            background-color: #ffb84d;
        }
    }
    &.app-button--download{
        color: #fff;
        background-color: var(--primary-blue);
        height: 35px;
        background-image: url(/assets/img/buttons/download.svg);
        background-size: 20px;
        padding-left: 35px;
        background-position: left 10px center;
        background-repeat: no-repeat;
        &:hover:not(:disabled){
            background-color: #58B4F9;
        }
    }
    &.app-button--auth-green{
        height: 44px;
        background-color: var(--medbook-green);
        color: #fff;
        &:hover:not(:disabled){
            background-color: #47CA75;
        }
        &.app-button--auth-green--lg{
            height: 48px;
            padding: 0 32px;
            font-size: 18px;
        }
    }
    .amount{
        color: #000;
        background-color: #f6f6f6;
        min-width: 20px;
        padding: 0 7px;
        border-radius: 99px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
    }
}

.link{
    color: var(--primary-blue);
    text-decoration: underline;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    text-align: left;
    &:disabled, &.disabled, &[inert]{
        opacity: .5;
        pointer-events: none;
        filter: saturate(0);
        &:disabled{
            pointer-events: all;
        }
    }
    &.link--add{
        padding-left: 25px;
        background-size: 21px;
        background-image: url(/assets/img/buttons/link-add.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-add--admin.svg); }
    }
    &.link--edit{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-edit.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-edit--admin.svg); }
    }
    &.link--hide{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-hide.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-hide--admin.svg); }
    }
    &.link--show{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-show.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-show--admin.svg); }
    }
    &.link--cv{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-cv.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-cv--admin.svg); }
    }
    &.link--download{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-download.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-download--admin.svg); }
    }
    &.link--upload{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-upload.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-upload--admin.svg); }
    }
    &.link--trash{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-trash.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-trash--admin.svg); }
    }
    &.link--crop{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-crop.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-crop--admin.svg); }
    }
    &.link--send{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-send.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-send--admin.svg); }
    }
    &.link--reset{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-reset.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-reset--admin.svg); }
    }
    &.link--refresh{
        padding-left: 25px;
        background-size: 20px;
        background-image: url(/assets/img/buttons/link-refresh.svg);
        background-position: left center;
        background-repeat: no-repeat;
        &.link--admin{ background-image: url(/assets/img/buttons/link-refresh--admin.svg); }
    }
}

.link-back{
    background: url(/assets/img/icons/back-blue.svg) no-repeat;
    padding-left: 15px;
    color: var(--primary-blue);
    font-size: 15px;
    line-height: 25px;
    background-size: 15px;
    font-weight: 500;
    background-position: left center;
    text-decoration: underline;
    &.link-back--12{
        font-size: 12px;
        line-height: 15px;
    }
}